import React from 'react'
import { Container, Row, Header } from "@edinburghairport/runway"

class ACPHeader extends React.Component {
    render() {
        return (
            <Container>
                <Row>
                    <div className="col-12 col-md-10">
                        <Header navigation={navigation} warningBeacon={{ show: false, }} />
                    </div>
                    <div className="col-12 col-md-2">
                        <img src="https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/modernising-our-skies.png" className="topbar__title" alt="Modernising Our Skies" />
                    </div>
                </Row>
            </Container>
        )
    }
}

const navigation = [
    {
        title: 'About',
        link: '/about',
    },
    {
        title: 'FAQs',
        link: '/faqs',
    },
];

export default ACPHeader;