import React from 'react'
import { Footer } from "@edinburghairport/runway"

class ACPFooter extends React.Component {

  render() {
    return (
      <Footer navigation={navigation} social={[]} bottomLinks={bottomLinks} />
    )
  }
}

const navigation = [{
  title: '',
  links: [
    { text: 'About', href: '/about' },
    { text: 'Consultation Material', href: '/previous-consultation-material' },
    { text: 'FAQs', href: '/faqs' },
    { text: 'Cookie Policy', href: '/cookie-policy' },
    { text: 'Contact Us', href: 'mailTo:airspace_change@edinburghairport.com'}
  ]
}];

const bottomLinks = [
  {text: 'Airspace Change Programme Privacy Notice', href: 'https://edinburghairport.s3-eu-west-1.amazonaws.com/files/acp2019/ACP-privacy_policy_2019_3-1-20.pdf'},
]

export default ACPFooter