import React, {Fragment} from 'react'
import ACPFooter from "../../components/ACPFooter"
import ACPHeader from "../../components/ACPHeader"
import '../../scss/main.scss';

class Layout extends React.Component {
constructor(props) {
  super(props)
  this.state = {
    isMobile: false
  }
  this.isMobile = this.isMobile.bind(this)
}

  isMobile() {
    this.props.isMobile && this.props.isMobile(window.innerWidth < 768);
  }

  render() {
    const{children} = this.props
    return (
      <Fragment>
     <ACPHeader/>
        {children}
        <ACPFooter/>
      </Fragment>
    )
  }
}

export default Layout